/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'svgxuse';
export const onClientEntry = async () => {
  // need to import a polyfill for Intersection Observer to be used in IE11
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
  // to support URL api in IE11
  if (typeof URL !== 'function') {
    await import('url-polyfill');
  }
};
